import React, { createContext, useEffect, useState } from "react";
import { loginWithGoogle } from "../API/auth";
import Cookies from 'js-cookie'

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(false);
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [user, setUser] = useState({});
    const [role, setRole] = useState("user");

    // Custom pin
    const [customPin, setCustomPin] = useState("");

    const allowAccess = ["saichandrateja@gmail.com ", "vibhanshujain99@gmail.com", "swapnilvv15@gmail.com"];
    const LoginGoogle = async (token) => {
        try {
            const res = await loginWithGoogle(token);
            console.log(res.email);
            if (allowAccess.includes(res.email)) {
                setAuth(true);
                setEmail(res.email);
                setToken(token);
                setName(res.given_name);
                setAuth(true);
                return res;
            }
            else {
                alert("You are not allowed to access this website");
                return null;
            }
        }
        catch (err) {
            console.log(err);
            return err;
        }
    };

    const Logout = () => {
        setAuth(false);
        setEmail("");
        setToken("");
        setId("");
        setName("");
        setUser({});
    };

    const saveCustomPin = () => {
        Cookies.set('customPin', customPin);
        alert('Custom pin saved');
    }

    const resetCustomPin = () => {
        Cookies.remove('customPin');
        setCustomPin('');
        alert('Custom pin reset');
    }

    useEffect(() => {
        setCustomPin(Cookies.get('customPin'));
    }, []);

    const authContext = {
        auth,
        setAuth,
        email,
        setEmail,
        token,
        setToken,
        id,
        setId,
        name,
        setName,
        LoginGoogle,
        Logout,
        user,
        setUser,
        role,
        setRole,
        customPin,
        setCustomPin,
        saveCustomPin,
        resetCustomPin
    };

    return (
        <AuthContext.Provider value={authContext}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;